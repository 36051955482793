<template>
    <div>
        <div class="btnPanel my-3 align-center">
            <v-btn color="error" @click="$emit('close')">
                <v-icon>mdi-arrow-left</v-icon>戻る
            </v-btn>
        </div>
        <div class="my-3">
            <v-text-field 
                v-model="search" 
                hide-details dense 
                prepend-icon="mdi-magnify"
                label="検索"
                class="my-6"
            />
            <div v-if="avatarData" class="avatarGrid">
                <avatar-card 
                    v-for="(user, index) in filteredUsers" :key="index" 
                    :_id = "user._id"
                    :name = "user.name"
                />
            </div>
        </div>
    </div>
</template>

<script>
import avatarCard from './Card.vue'
export default {
    components:{
        avatarCard
    },
    data:()=>({
        avatarData:null,
        search:"",
    }),
    computed:{
        filteredUsers(){
            if(this.search)
                return this.avatarData.filter(avatar => (avatar.name.toUpperCase()).includes(this.search.toUpperCase()))
            else return this.avatarData
        }
    },
    async mounted(){
        this.avatarData = await this.getAvatarList()
    },
    methods:{
        async getAvatarList(){
            const response = await this.$store.dispatch("get", {url:'/api/getUserList?uOnly=true', showUI:true})
            if(response && response.result) return response.result
        }
    }
}
</script>

<style scoped>
.avatarGrid{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 160px));
    gap:.5em;
    justify-content: center;
} 
</style>