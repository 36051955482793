<template>
  <div>
    <v-btn color=error @click="$emit('close')">戻る</v-btn>
    <div class="d-flex mt-3">
        <v-file-input
            v-model="uploadedCSV"
            outlined
            accept=".csv"
            label="CSVファイルをアップロード"
            @change="checkFile"
        ></v-file-input>
    </div>
    <div v-if="parsedData">
        <div class="text-center mb-3">
            <div>一回確認しましょう。以下のデーターでよろしいでしょうか？</div>
            <div class="d-flex justify-center">
                <v-btn color="success" class="mr-3" @click="uploadServerCheck">はい、エラーチェックを行って、登録に進みます。</v-btn>
                <v-btn color="error" @click="reset">いいえ、もう一度やり直します。</v-btn>
            </div>
        </div>
        <div v-if="error" style="height:250px; overflow-y:scroll;" class="my-3">
            <div class="error--text">エラーが{{error.count}}件検出しました</div>
            <v-simple-table class="error--text">
                <template v-slot:default>
                    <tbody>
                        <tr
                            v-for="(errMsg, index) in error.msg"
                            :key="index"
                        >
                            <td>{{ index + 1 }}</td>
                            <td>{{ errMsg }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
        <v-simple-table>
            <template v-slot:default>
            <thead>
                <tr>
                    <th>行</th>
                    <th class="text-left" v-for="(header,index) in headers" :key="index">
                        {{header}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(user, index) in parsedData"
                    :key="index"
                >
                    <td>{{ index + 1 }}</td>
                    <td>{{ user[0] }}</td>
                    <td>{{ user[1] }}</td>
                    <td>{{ user[2] }}</td>
                    <td>{{ user[3] }}</td>
                    <td>{{ user[4] }}</td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
    </div>
  </div>
</template>

<script>
import {io} from "socket.io-client";
import Papa from 'papaparse'
import Encoding from 'encoding-japanese';
export default {
    data:()=>({
        parsedData:null,
        headers:null,
        uploadedCSV:null,
        error:null,
        socket:null,
    }),
    methods:{
        async uploadServerCheck(){
            this.socket = io(this.$hostUrl);
            this.socket.on("reportProgress", (message)=>{
                this.$store.commit("setSocketLoad",{
                        display:true,
                        title:"ユーザー登録中",
                        value:Number(message)
                    })
            })
            this.socket.on("disconnect", (message)=>{
                this.$store.commit("setSocketLoad",{
                    display:false,
                })
            })
            const response = await this.$store.dispatch(
                'post',
                {url:'/api/checkCSVData', rawData:{headers:this.headers, data:this.parsedData}, showUI:true}
            )
            if(response.result){
                if(response.result.count > 0)
                    this.error = response.result
                else
                    this.$emit("close")
            }
        },
        reset(){
            this.parsedData = null
            this.headers = null
            this.uploadedCSV = null
            this.error = null
        },
        checkFile(e){
            if(!this.uploadedCSV) return
            let vm = this
            const reader = new FileReader();
            reader.onload = (e) => {
                const codes = new Uint8Array(e.target.result);
                const encoding = Encoding.detect(codes);
                const unicodeString = Encoding.convert(codes, {
                    to: 'unicode',
                    from: encoding,
                    type: 'string',
                });
                Papa.parse(unicodeString,{
                    complete:async(results, file)=>{
                        vm.headers = results.data.shift()
                        vm.parsedData = results.data
                    }
                })
            };
            reader.readAsArrayBuffer(e);
            
        }
    }
}
</script>

<style>

</style>