<template>
    <v-card>
        <v-img
            width="160"
            height="220"
            :src="imageSrc"
        ></v-img>
        <v-card-text class="mt-3 py-0">
            <v-form v-model="valid">
                <v-file-input
                    prepend-icon="mdi-image"
                    accept="image/*"
                    label="アップロード"
                    outlined dense
                    v-model="rawImg"
                    :rules="[
                        v => (!!v && v.size<2000000) || '最大２MB'
                    ]"
                ></v-file-input>
            </v-form>
        </v-card-text>
        <v-card-text class="py-0">
            <v-btn 
                color="error" width="100%"
                @click="deleteAvatar"
            >削除</v-btn>
        </v-card-text>
        <v-card-title class="mb-3 py-0">
            <div style="width:100%; text-align:center;">
                {{name}}
            </div>
        </v-card-title>
    </v-card>
</template>

<script>
export default {
    props:["name", "_id"],
    data:()=>({
        imageSrc:null,
        rawImg:null,
        valid:false
    }),
    methods:{
        imageExists(image_url){
            let http = new XMLHttpRequest();
            http.open('HEAD', image_url, false);
            http.send();
            return http.status != 404;
        },
        async saveAvatar(formData){
            console.log(this.formData)
            let fd = new FormData();
            fd.append('name', formData.name)
            fd.append('img', formData.img)
            await this.$store.dispatch("post", {url:'/api/saveAvatar', rawData:fd,　showUI:true})
        },
        async deleteAvatar(){
            await this.$store.dispatch("post", {url:'/api/deleteAvatar', rawData:{target:this._id},　showUI:true})
            this.imageSrc = `${this.$hostUrl}/img/avatar/default.jpg`
        }
    },
    watch:{
        async rawImg(){
            //Check size
            if(this.rawImg.size > 2000000) return
            if(this.rawImg){
                this.imageSrc = URL.createObjectURL(this.rawImg)
                await this.saveAvatar({name:this._id, img:this.rawImg})
            }
        }
    },
    mounted(){
        //Default
        if(
           ! this.imageExists(`${this.$hostUrl}/img/avatar/x80${this._id}.jpeg`)
        ) this.imageSrc = `${this.$hostUrl}/img/avatar/default.jpg`
        else this.imageSrc = `${this.$hostUrl}/img/avatar/x80${this._id}.jpeg`

    }
}
</script>
