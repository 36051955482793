<template>
    <div>
        <div v-if="!avatarMode">
            <div v-if="!importMode">
                <div class="btnPanel my-3 align-center">
                    <v-btn color=success @click="display.register = true">
                        <v-icon>mdi-account-plus</v-icon>
                        新規
                    </v-btn>
                    <v-btn color="error" @click="initDelete">
                        <v-icon>mdi-delete</v-icon>選択されたユーザーを削除
                    </v-btn>
                    <v-btn color="success" @click="importMode = true">
                            <v-icon>mdi-account-multiple-plus</v-icon>CSV一括登録
                    </v-btn>
                    <v-btn color="success" @click="downloadCSVTemplate">
                        <v-icon>mdi-download</v-icon>CSVテンプレートダウンロード
                    </v-btn>
                    <v-btn color="warning" @click="avatarMode = true">
                        <v-icon>mdi-image</v-icon>画像管理
                    </v-btn>
                </div>
                <user-list
                    :key="'userListKey'+userListKey"
                    :listOfUsersToFilter="[$store.state.userProfile.id]" 
                    :usersOnly="true"
                    :show="{userPin:true, userContractType:true, joined:true}" @syncData="selected = $event"  />
                <v-dialog v-model="display.register" max-width="500px">
                    <v-sheet>
                        <register-new-user @close="display.register = false; closeNRefresh;" />
                    </v-sheet>
                </v-dialog>
            </div>
            <div v-else>
                <csv-register @close='importMode = false'/>
            </div>
        </div>
        <div v-else>
            <AvatarUpload @close="avatarMode = false" />
        </div>
    </div> 
</template>

<script>
import {io} from "socket.io-client";
import UserList from '../../components/DataTables/UserList.vue'
import RegisterNewUser from '../../components/Forms/RegisterNewUser.vue'
import CsvRegister from '@/components/UserRegistration/CsvRegister.vue'
import Papa from 'papaparse'
import AvatarUpload from '../../components/AvatarUpload/Panel.vue'
export default {
    data:()=>({
        display:{
            register:false
        },
        userListKey:0,
        selected:[],
        importMode:false,
        socket:null,
        avatarMode:false
    }),
    methods:{
        async downloadCSVTemplate(){
            const teamList = await this.$get.getTeamList(true)
            const userContractType = ["正社員","アルバイト","パート"]
            const templateLayout = {
                "fields":["名前","4桁以上のPIN","雇用形態","班","入社日(年年年年-月月-日日)"],
                "data":[
                    ["","","",""],
                    ["","","",""],
                    ["","","",""],
                ]
            }
            const referenceLayout = {
                "data":[
                    ["班", ...teamList],
                    ["雇用区分", ...userContractType],
                ]
            }
            const templateCSV_String = Papa.unparse(templateLayout)
            const referenceCSV_String = Papa.unparse(referenceLayout)
            this.$gf.downloadCSV(templateCSV_String, "ユーザー登録テンプレート.csv")
            this.$gf.downloadCSV(referenceCSV_String, "参照リスト.csv")
        },
        closeNRefresh(){
            this.display.newUser = false
            this.userListKey ++
        },
        async initDelete(){
            this.socket = io(this.$hostUrl);
            let selected
            if(this.selected.length > 0) selected = this.selected
            else return
            let tmpText = ""
            selected.forEach(user => {
                tmpText +=  `[${user.name}] `
            });
            if (
                await this.$root.$confirm.open(
                    "削除",
                    "選択した項目を全部削除しますか？元には戻れません。\n" +  tmpText,
                    { color: "error" }
                )
            ){
                
                this.socket.on("reportProgress", (message)=>{
                    this.$store.commit("setSocketLoad",{
                            display:true,
                            title:"ユーザー削除中",
                            value:Number(message)
                        })
                })
                this.socket.on("disconnect", (message)=>{
                    this.$store.commit("setSocketLoad",{
                        display:false,
                    })
                })
                await this.$store.dispatch("post", {
                    url: "/auth/deleteUsers",
                    rawData: selected,
                });
                this.userListKey ++
            }
        },
    },
    components:{
        UserList,
        RegisterNewUser,
        CsvRegister,
        AvatarUpload
    }
}
</script>

<style>

</style>