<template>
    <div>
        <v-sheet class="pa-6" :key="formKey" v-if="teamData">
            <v-card >
                <v-card-text>
                    <v-form v-model="formValid">
                        <v-text-field
                            outlined
                            v-model="formData.name"
                            :rules="rules.name"
                            label="名前"
                        />
                        <v-text-field
                            outlined
                            v-model="formData.userPin"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="rules.userPin"
                            :type="show1 ? 'number' : 'password'"
                            label="最低4桁のPINを入力してください"
                            @click:append="show1 = !show1"
                        />
                        <v-select 
                            class="mt-4" 
                            v-model="formData.userContractType"
                            :items="contactTypes" 
                            item-text="label"
                            item-value="label"
                            label="雇用形態"
                            :rules="rules.userContractType" 
                            outlined />
                        <v-select 
                            class="mt-2" 
                            v-model="formData.userTeam"
                            :items="teamData" 
                            item-text="teamName"
                            item-value="_id"
                            label="班"
                            :rules="rules.teamSelect"
                            outlined/>
                        <v-date-picker
                            v-model="formData.joined"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="rules.userPin"
                            locale=ja
                        />
                    </v-form>
                </v-card-text>
            </v-card>
            <v-card-actions class="d-flex"> 
                <v-btn width="50%" color=error @click='$emit("close")'>キャンセル</v-btn>
                <v-btn width="50%" color=success @click="submitUser" :disabled="!formValid">登録</v-btn>
            </v-card-actions>
        </v-sheet>
    </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
    data:()=>({
        formValid: false,
        show1 : false,
        formKey:0,
        contactTypes:[
            {
                label:"正社員",
                code:0
            },
            {
                label:"アルバイト",
                code:1
            },
            {
                label:"パート",
                code:2
            },
        ],
        teamData:null,
        formData:{
            name : "",
            userPin : "",
            userContractType: "",
            joined:dayjs().format("YYYY-MM-DD")
        },
        rules:{
            name: [ v => !!v || "名前を入れてください" ],
            userPin : [ v => v.length >= 4 || "最低４桁"],
            userContractType : [ v => !!v || "雇用形態を入れてください" ],
            teamSelect: [ v => !!v || "班を割り当ててください" ]
        }
    }),
    mounted(){
        this.init()
    },
    methods:{
        async init(){
            try{
                this.$store.commit('setLoadingScreen',{display:true})
                const response = await this.$store.dispatch('get', {url:'/api/getTeamInfo', showUI:true})
                if(!response.result) return
                this.teamData = response.result
                this.$store.commit('setLoadingScreen',{display:false})
            }catch(err){
                if(!this.$store.state.isProduction) console.log(err)
                this.$store.commit('setSnack',{
                    display:true,
                    iconColor:"red",
                    icon:"mdi-alert-circle",
                    text: err.response? err.response.data  :"エラーが発生しました。" 
                })    
            }
        },
        async submitUser(){
            this.formData.joined = dayjs(this.formData.joined).format('YYYY-MM-DD')
            let response = await this.$store.dispatch("post", {
                url: `/auth/registerUser`,
                rawData:this.formData,
                showUI:true,
            });
            if (response && response.result){
                this.formKey ++
                this.$emit('close')
            }
        }
    } 
}
</script>